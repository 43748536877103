import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactPlayer from 'react-player';
import { Analytics } from '@vercel/analytics/react';
import axios from 'axios';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './App.css';

import G from './G';


import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper';

import buttonIcon from './images/onlyfans.jpg';
import buttonIcon2 from './images/clear.png';

function App() {
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [fetchAttempted, setFetchAttempted] = useState(false);
  const currentDate = new Date();
  const fiveDaysBefore = new Date(currentDate.getTime() - 4 * 24 * 60 * 60 * 1000);
  const oneDayAfter = new Date(currentDate.getTime() + 2 * 24 * 60 * 60 * 1000);
  const [timeLeft, setTimeLeft] = useState(10 * 60);

  const formatDateString = (date) => {
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
  };





useEffect(() => {
  const fetchLocationData = () => {
    fetch('/api/location')
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        setCountry(decodeURIComponent(data.country));
        setCity(decodeURIComponent(data.city));
        
        // Remove numbers from city and country names
        const cleanCity = data.city.replace(/[0-9]/g, '');
        const cleanCountry = data.country.replace(/[0-9]/g, '');

        const address = `${encodeURIComponent(cleanCity)}, ${encodeURIComponent(cleanCountry)}`;
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyBTT1Epe-qKRGcLSPos2waBZ-2M5dkMfew`;
        console.log("URL Requested: ", url); // Log the URL
        return axios.get(url);
      })
      .then(response => {
        console.log("Google Geocoding API Response: ", response.data); // Log the full response
        const results = response.data.results;
        if (results.length > 0) {
          const addressComponents = results[0].address_components;
          const stateObj = addressComponents.find(component => component.types.includes('administrative_area_level_1'));
          if (stateObj) {
            setRegion(stateObj.long_name);
          }
        }
        setFetchAttempted(true);
      })
      .catch(error => {
        console.error('Fetch error:', error);
        setCountry('Unavailable');
        setRegion('');
        setCity('');
        setFetchAttempted(true);
      });
  };

  if (!region && !fetchAttempted) {
    fetchLocationData();
  }
}, [region]); // Dependency on region

  useEffect(() => {
    // Update the countdown timer every second
    const timer = setTimeout(() => {
      setTimeLeft((prevTimeLeft) => (prevTimeLeft > 0 ? prevTimeLeft - 1 : 0));
    }, 1000);

    // Clear the timer when component unmounts or timeLeft changes
    return () => clearTimeout(timer);
  }, [timeLeft]);

  // Function to format the remaining time as "0h 0m 0s"
  const formatTimeLeft = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}m ${seconds}s`;
  };

  useEffect(() => {
    const handleResize = () => {
      const pcWidth = 1024; // Define the minimum width for a PC screen
      if (window.innerWidth >= pcWidth) {
        window.location.href = 'https://onlyfans.com/eveegale/c4'; // Redirect URL for desktop
      }
    };

    handleResize(); // Call on mount to check immediately
    window.addEventListener('resize', handleResize); // Add listener for any resize events

    return () => window.removeEventListener('resize', handleResize); // Clean up listener
  }, []);


  return (
    <Router>
    <Routes>
      <Route path="/" element={
    <div className="landing-container">
      <video
        autoPlay // Start playing the video as soon as it's ready
        playsInline // This attribute is important for playing the video inline on iOS devices
        preload="auto" // Suggests to the browser that it should start downloading the video as soon as possible
        loop // Keeps the video looping
        controls // Add this if you want to show controls like play, pause, volume, etc.
        style={{
          position: "absolute",
          width: "100%",
          height: "125%", // Increase the height to cover the extra space when the video is moved up
          top: "-25vh", // Move the video up by 25% of the viewport height
          left: 0,
          objectFit: "cover",
          zIndex: 1
        }}
      >
        <source src="https://ofmpics.com/eveegale.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="fade-section">
        <div className="top-fade-text"><strong>Evelyn</strong></div> 
        <div className="mid-fade-text">A blonde with extraordinary charisma<br/>
        
        <div className="flex items-center text-center flex-wrap justify-center">
          <a href="https://instagram.com/msgaleex" aria-label="Instagram Profile">
            <svg width="28" height="28" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg" style={{ fill: 'rgb(254, 254, 254)', width: '28px', height: '28px', padding: '8px' }}>
              <path d="M65 16.6667H135C161.667 16.6667 183.333 38.3334 183.333 65.0001V135C183.333 147.819 178.241 160.113 169.177 169.177C160.113 178.241 147.819 183.333 135 183.333H65C38.3333 183.333 16.6667 161.667 16.6667 135V65.0001C16.6667 52.1813 21.7589 39.8875 30.8232 30.8233C39.8874 21.759 52.1812 16.6667 65 16.6667ZM63.3333 33.3334C55.3768 33.3334 47.7462 36.4941 42.1201 42.1202C36.494 47.7463 33.3333 55.3769 33.3333 63.3334V136.667C33.3333 153.25 46.75 166.667 63.3333 166.667H136.667C144.623 166.667 152.254 163.506 157.88 157.88C163.506 152.254 166.667 144.623 166.667 136.667V63.3334C166.667 46.7501 153.25 33.3334 136.667 33.3334H63.3333ZM143.75 45.8334C146.513 45.8334 149.162 46.9309 151.116 48.8844C153.069 50.8379 154.167 53.4874 154.167 56.2501C154.167 59.0128 153.069 61.6623 151.116 63.6158C149.162 65.5693 146.513 66.6668 143.75 66.6668C140.987 66.6668 138.338 65.5693 136.384 63.6158C134.431 61.6623 133.333 59.0128 133.333 56.2501C133.333 53.4874 134.431 50.8379 136.384 48.8844C138.338 46.9309 140.987 45.8334 143.75 45.8334ZM100 58.3334C111.051 58.3334 121.649 62.7233 129.463 70.5373C137.277 78.3513 141.667 88.9494 141.667 100C141.667 111.051 137.277 121.649 129.463 129.463C121.649 137.277 111.051 141.667 100 141.667C88.9493 141.667 78.3512 137.277 70.5372 129.463C62.7232 121.649 58.3333 111.051 58.3333 100C58.3333 88.9494 62.7232 78.3513 70.5372 70.5373C78.3512 62.7233 88.9493 58.3334 100 58.3334ZM100 75.0001C93.3696 75.0001 87.0107 77.634 82.3223 82.3224C77.6339 87.0108 75 93.3697 75 100C75 106.631 77.6339 112.989 82.3223 117.678C87.0107 122.366 93.3696 125 100 125C106.63 125 112.989 122.366 117.678 117.678C122.366 112.989 125 106.631 125 100C125 93.3697 122.366 87.0108 117.678 82.3224C112.989 77.634 106.63 75.0001 100 75.0001Z"></path>
            </svg>
          </a>
        </div></div> 
        

      </div>
      <div className="text-section">
  <a href="https://onlyfans.com/eveegale/c4" className="custom-button" aria-label="Onlyfans">
    <div className="button-content">
      <div className="button-icon">
        <img src={buttonIcon} alt="link" />
      </div>
      <div className="button-text">
        <div>Onlyfans<br/>Subscribe at your own risk. I'm a bit of a freak when it comes to toys 🔥</div>
      </div>
      <div className="button-icon">
        <img src={buttonIcon2} alt="link" />
      </div>
    </div>
  </a>
  <div className="distance-container">
    <img src="../images/location.svg" alt="Location icon" className="location-icon"/>
    <span className="distance-text"><strong>2.4 miles away</strong></span>
  </div>
</div>

      <Analytics/>
    </div>


} />
<Route path="/I-Am-Waiting-For-You/onlyfans.com/eveegale" element={<G />} />
<Route path="/onlyfans.com/eveegale" element={<G />} />
<Route path="/MeetUps-Only/onlyfans.com/eveegale" element={<G />} />
<Route path="/Collaborations-Only/onlyfans.com/eveegale" element={<G />} />
</Routes>
</Router>


  );
}

export default App;
